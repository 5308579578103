import React, { Component } from 'react';

import { Image } from 'react-bootstrap';
import './complain.scss';
import Breadbg from '../../assets/images/qsa/breadbg.jpg';

/* eslint-disable-next-line */


class Complain extends Component {
  render(){
    return (
      <div>
        <div className="inner-banner text-center">
          <div className="bannerImage">
            <Image src={Breadbg}></Image>
          </div>
            <div className="container">
                <ul className="page-breadcrumb">
                  <li>
                    <a href="./index.html">Home</a>
                  </li>
                  <li>CMS</li>
                </ul>		
                <h1> Complain Managment System</h1>
            </div>
        </div>
        <section className="kc-elm kc-css-36515 kc_row" >
          <div className="kc-row-container">
              <div className="kc-wrap-columns">
                <div className="kc-elm kc-css-843552 kc_col-sm-12 kc_column kc_col-sm-12">
                    <div className="kc-col-container">
                      <section className="single-service-proj service-style-one sec-pad solutions-page">
                          <div className="container">
                              <div className="sec-title text-center mb-3">
                                  <span></span>
                                  <h2>Complaint Management system</h2>
                              </div>
                              <p>
                                  Solve your complaint management issues with fully customized software and experts who will walk you through each step. We provide full, ongoing support. Talk with an expert ready to help customize a software solution to manage complaints and improve customer and team-member relationships. We have deployed complaint management solutions to leading organizations & companies. Your unique challenges are important. 
                              </p>
                              <p>
                                  Nowadays, customers approach you from everywhere, so you need complaint tracking software with multichannel capabilities to respond quicker to their queries. QSA Desk collects all support tickets from different channels and organizes them under one tab, to help agents respond to all of them. These channels include:
                              </p>
                              
                              <div className="content">
                                  <ul>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Acknowledge receipt of the complaint</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Accurately record complaint information</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Attach time frames for resolution of complaints</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Flexible workflow</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Communication SMS & EMAILS</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	QA evaluation assessment investigating reports follow up</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Maintaining compliance</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Reports of complaint by month, average days open, average days past due</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Average days to process the task</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Automatically notify affected departments and personnel</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Send automatically email to department head</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Reminder Popup automatically for complaint/ service/ due date</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Send SMS to customer Complaint register confirmation</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Send SMS to customer complaint complete confirmation</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	feedback request email to customers</strong></p></li>
                                    <li><i className="fa fa-check-circle"></i><p><strong>•	Manage Client Data and its privacy</strong></p></li>
                                </ul>
                              </div>
                            </div>
                      </section>
                    </div>
                </div>
              </div>
          </div>
        </section>
      </div>      
    );
  }
}

export default Complain;

