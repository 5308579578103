import React, { Component } from 'react';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import Complain from '../../../components/complain/complain';
import Footer from '../../../components/footer/footer';

import './index.scss';

class Index extends Component {

  render(){
    return (
      <Layout>
        <div className="innerService">
          <Header></Header>
          <Complain></Complain>
          <Footer></Footer>
        </div>
      </Layout>
    );
  }
}

export default Index;
